import React from 'react';
import { withErrorBoundary } from '@/utils/errors';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import Shimmer from './Shimmer';

const SkeletonWrapper = ({ children, noMargin, card, fitContent, fluid }) => {
  const background = useSelector(state => state.currentUser.profile.background);

  return (
    <div
      className={classNames(
        'skeleton-wrapper',
        card && 'card',
        fluid && 'fluid',
        noMargin && 'no-margin',
        fitContent && 'fit-content',
        background || 'backgrund-default'
      )}
    >
      {children}
      <Shimmer />
    </div>
  );
};

export default withErrorBoundary(SkeletonWrapper);
